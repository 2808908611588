
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import AuthService from '@/services/auth';
import Gtm from '@/utils/gtm';
import strings from '@/utils/strings';

const authService = new AuthService();

export default {
  name: 'HeaderBanners',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters({
      region: 'getGuestRegion',
      promoCode: 'getPromoCode',
      pass: 'getPass',
      partnerCode: 'getPartnerCode',
      hasCustomPlan: 'hasCustomPlan',
      partner: 'getParceiro',
      isItau: 'getIsItau',
      campaign: 'getCampaign',
      promoDetails: 'getPromoDetails',
      isPromotionRegion: 'getIsPromotionRegion'
    }),
    isPartner() {
      return this.$route.query.utm_source || this.partnerCode;
    },
    showCampaignBanner() {
      const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);
      return (
        !this.authenticated() &&
        !isOwnPlansPartner &&
        !this.showNewCampaignBanner &&
        !this.isPromotionRegion
      );
    },
    showNewBanners() {
      return (
        this.$route?.query?.newLP === 'true' && this.$route.path !== '/contrate'
      );
    },
    showNewCampaignBanner() {
      const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);
      return (
        !this.authenticated() &&
        !isOwnPlansPartner &&
        !this.isPromotionRegion &&
        this.showNewBanners
      );
    },
    showPromotionalBanners() {
      return (
        this.promoCode &&
        this.promoDetails?.banners?.banner_mobile &&
        this.isPromotionRegion
      );
    }
  },
  mounted() {
    this.getPartnerConfig();
    this.setCampaign();
  },
  methods: {
    ...mapActions(['setPartner', 'setPassCode', 'setCampaign']),
    ...mapMutations([
      'set_isLogoPorto',
      'set_pass',
      'set_partnerCode',
      'set_userType'
    ]),
    getPartnerConfig() {
      const cookieUtmSource = this.partnerCode;

      const utmSource = this.$route.query.utm_source || cookieUtmSource;

      if (
        this.$route.query.utm_source &&
        cookieUtmSource &&
        cookieUtmSource !== this.$route.query.utm_source &&
        this.$route.query.utm_source !== undefined
      ) {
        this.set_partnerCode(null);
        this.set_pass(null);
      }

      const cookiePass = this.pass;
      const isPass = this.$route.query.pass || cookiePass;

      if (utmSource) {
        const hasPortopetUtm = ['portopet', 'health4pet', 'nofaro'].includes(
          utmSource
        );

        if (hasPortopetUtm) {
          this.set_isLogoPorto(hasPortopetUtm);
          return;
        }

        if (!utmSource) return;
        this.set_isLogoPorto(false);
        const url = isPass
          ? `petlovepass/company/config?utmSource=${utmSource}`
          : `partners/config?utm_source=${utmSource}`;

        this.$axios.get(url).then((response) => {
          const data = {
            ...response.data
          };

          if (isPass) {
            data.model = 'pass';
          }

          this.setPartner(data);
          this.set_isLogoPorto(data.status_logo_porto);
          if (data?.status) {
            this.set_userType('partner');
          } else {
            this.set_userType('B2C');
          }
        });
      }
    },
    authenticated() {
      if (process.client) {
        return authService.isLogged();
      }
    },
    handleCampaignClick() {
      const campaignName = strings.stringToSlug(this.campaign?.alt);
      Gtm.sendCustomEvent('click', `banner:${campaignName}`, 'lp-saude');
      this.showModal = true;
    },
    handleCampaignModalClick() {
      Gtm.sendCustomEvent(
        'click',
        'modal-regras-promocionais:botao:entendi',
        'lp-saude'
      );
      this.showModal = false;
    }
  }
};
