import { render, staticRenderFns } from "./PartnerBanner.vue?vue&type=template&id=4ccbacb1&scoped=true"
import script from "./PartnerBanner.vue?vue&type=script&lang=js"
export * from "./PartnerBanner.vue?vue&type=script&lang=js"
import style0 from "./PartnerBanner.vue?vue&type=style&index=0&id=4ccbacb1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccbacb1",
  null
  
)

export default component.exports