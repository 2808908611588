
export default {
  name: 'NewCampaignBanner',

  props: {
    campaign: {
      type: Object,
      required: true
    }
  },

  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      currentDate: new Date(currentDate)
    };
  },

  computed: {
    showCampaignBanner() {
      return (
        this.currentDate >= this.startDate && this.currentDate <= this.endDate
      );
    },
    startDate() {
      if (!this.campaign || !this.campaign?.start) return '';
      return new Date(this.campaign?.start);
    },
    endDate() {
      if (!this.campaign || !this.campaign?.end) return '';
      return new Date(this.campaign?.end);
    },
    applyPaddingTop() {
      return this.$route.path === '/';
    }
  },

  mounted() {
    if (this.show) {
      document.cookie = `campaign_expiration=${this.endDate.toUTCString()};secure`;
    }
  }
};
