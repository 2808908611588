
export default {
  name: 'PromotionalBanners',
  props: {
    promoDetails: {
      type: Object,
      required: true
    }
  },

  computed: {
    applyPaddingTop() {
      return this.$route.path === '/' && this.$device.isMobile;
    }
  }
};
