
import { mapGetters } from 'vuex';

export default {
  name: 'CampaignBanner',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return {
      currentDate: new Date(currentDate)
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'getCampaign'
    }),
    show() {
      return (
        this.currentDate >= this.startDate && this.currentDate <= this.endDate
      );
    },
    startDate() {
      if (!this.campaign || !this.campaign?.start) return '';
      return new Date(this.campaign?.start);
    },
    endDate() {
      if (!this.campaign || !this.campaign?.end) return '';
      return new Date(this.campaign?.end);
    },
    applyPaddingTop() {
      return this.$route.path === '/' && this.$device.isMobile;
    }
  },
  mounted() {
    if (this.show) {
      document.cookie = `expires=${this.endDate.toUTCString()};secure`;
      const endTime = this.endDate.getTime();
      const currentTime = this.currentDate.getTime();

      const reload = endTime - currentTime;
      setTimeout(() => {
        this.currentDate = new Date();
      }, reload);
    }
  }
};
