
import { mapGetters } from 'vuex';

export default {
  name: 'PartnerBanner',
  computed: {
    ...mapGetters({
      parceiro: 'getParceiro'
    }),
    padding() {
      return this.parceiro.banner_padding || '12px';
    },
    cssVars() {
      return {
        background: this.parceiro.color_background,
        color: this.parceiro.color_text,
        padding: this.parceiro.banner_padding,
        '--max-height': 100 - this.padding.split('px')[0] * 2 + 'px'
      };
    }
  }
};
