import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d1fa9efa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=d1fa9efa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1fa9efa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartnerBanner: require('/app/components/header/HeaderBanners/PartnerBanner.vue').default,CampaignBanner: require('/app/components/header/HeaderBanners/CampaignBanner.vue').default,NewCampaignBanner: require('/app/components/header/HeaderBanners/NewCampaignBanner.vue').default,PromotionalBanners: require('/app/components/header/HeaderBanners/PromotionalBanners.vue').default,NewPromotionalBanner: require('/app/components/header/HeaderBanners/NewPromotionalBanner.vue').default,CaModal: require('/app/node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue').default})
